import { createContext, useContext, useEffect, useState } from 'react';

import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';

import { useAuth } from '@clerk/nextjs';

import { useTradeInContext } from '@/context/use-trade-in-provider';
import { useUserAuth } from '@/context/user-auth-provider';

import type { Offer } from '@@/ItemAndGrading/entities/offer';
import { OFFER_STATUS, OFFER_TARGET } from '@@/Pricing/constants';
import { BuyProduct } from '@@/Pricing/entities/buy-product';
import { useBestBid } from '@@/Pricing/hooks/use-bid-service';
import { useOfferSocketIo } from '@@/Pricing/hooks/use-offer-socket-io';
import { BestBidResponse } from '@@/Pricing/services/bid-service';

import { STORAGE_KEYS, useLocalStorage } from '@/hooks/use-local-storage';

import { ROUTES } from '@/constants';

export type PricingContext = {
  bestBidData: BestBidResponse;
  setBestBidData: (data: BestBidResponse) => void;
  isBestBidLoading?: boolean;
  isBestBidWithError?: boolean;
  cleanUpPricing: () => void;
  buyProduct?: BuyProduct | null;
  setBuyProduct: (data: Partial<BuyProduct>) => void;
  offer: Offer;
  isOfferSuccess: boolean;
};

const pricingContext = createContext<PricingContext>({} as PricingContext);

const routesAfterAuction = [
  ROUTES.TRADE_IN_AUCTION,
  ROUTES.TRADE_IN_CONTRACT,
  ROUTES.TRADE_IN_SUCCESS,
  ROUTES.TRADE_IN_GIVE_UP,
];

export const PricingProvider: React.FCWC = ({ children }) => {
  const storage = useLocalStorage();
  const { interceptorId, isSignedIn } = useUserAuth();
  const { isLoaded } = useAuth();
  const { itemId, customerRefId, tradeInId, tradeInData } = useTradeInContext();
  const { offer, isOfferSuccess } = useOfferSocketIo({ tradeInId: tradeInId });
  const pathName = usePathname();
  const { push } = useRouter();

  const isAuthLoaded = isLoaded && isSignedIn && interceptorId !== null;

  const bestBidResponse = useBestBid({
    retry: 2,
    itemId: itemId,
    buyProductPrice: storage?.getItem(STORAGE_KEYS.DOJI_BUY_NEW_PRODUCT)?.price ?? null,
    isCustomerTradeIn: !!customerRefId,
    enabled: !!itemId && Boolean(customerRefId || (!customerRefId && isAuthLoaded)),
  });

  const [bestBidData, setBestBidData] = useState(bestBidResponse.bestBidData ?? ({} as BestBidResponse));
  const [buyProduct, setBuyProduct] = useState<BuyProduct | null>(
    storage?.getItem(STORAGE_KEYS.DOJI_BUY_NEW_PRODUCT) ?? null
  );

  useEffect(() => {
    if (bestBidResponse.isBestBidSuccess && bestBidResponse.bestBidData) {
      setBestBidData(bestBidResponse.bestBidData);
    }
  }, [bestBidResponse.isBestBidSuccess, bestBidResponse.bestBidData]);

  useEffect(() => {
    storage?.setItem(STORAGE_KEYS.DOJI_BUY_NEW_PRODUCT, buyProduct);
  }, [buyProduct]);

  useEffect(() => {
    if (
      !!offer?.targetRefId &&
      tradeInData?.pricing?.target === OFFER_TARGET.DOJIZAP &&
      !routesAfterAuction?.includes(pathName as ROUTES)
    ) {
      if (offer.status === OFFER_STATUS.ACTIVE) push(ROUTES.TRADE_IN_AUCTION);
      else if (offer.status === OFFER_STATUS.ACCEPTED) push(ROUTES.TRADE_IN_AUCTION_SUCCESS);
      else if (offer.status === OFFER_STATUS.EXPIRED) push(ROUTES.TRADE_IN_AUCTION_BUYER_NOT_FOUND);
    }
  }, [offer]);

  const cleanUpPricing = () => {
    setBestBidData({} as BestBidResponse);
    storage?.removeItem(STORAGE_KEYS.DOJI_BUY_NEW_PRODUCT);
  };

  const value = {
    bestBidData,
    setBestBidData,
    isBestBidLoading: bestBidResponse.isBestBidLoading,
    isBestBidWithError: bestBidResponse.isBestBidError,
    cleanUpPricing,
    buyProduct,
    setBuyProduct,
    offer,
    isOfferSuccess,
  };

  return <pricingContext.Provider value={value}>{children}</pricingContext.Provider>;
};

export function usePricingContext() {
  return useContext(pricingContext);
}
