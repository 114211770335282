import { useEffect } from 'react';

import { Query, useQuery } from '@tanstack/react-query';

import { ApiResponse } from '@/@types/api-response';

import { Item } from '@@/ItemAndGrading/entities/item';
import ItemService from '@@/ItemAndGrading/services/item-service';

import { LOG_TYPE, useLogs } from '@/hooks/use-logs';

import { fetchKeys } from '@/constants/fetch-keys';

type Props = {
  itemId: string;
  refetchInterval?:
    | number
    | false
    | ((query: Query<ApiResponse<Item>, Error, ApiResponse<Item>, string[]>) => number | false | undefined)
    | undefined;

  enabled?: boolean;
};

export const useFindItem = ({ itemId, refetchInterval = false, enabled = true }: Props) => {
  const { data, isSuccess, isLoading, refetch } = useQuery({
    queryKey: [fetchKeys.item.find, itemId],
    queryFn: () => ItemService.findItem(itemId),
    refetchInterval: refetchInterval,
    enabled: enabled,
  });

  return { itemData: data?.response, isItemSuccess: isSuccess, isItemLoading: isLoading, refetchItem: refetch };
};

export const useFuncGradingReport = ({
  itemId,
  enabled = true,
  retry = true,
}: {
  itemId: string;
  enabled?: boolean;
  retry?: number | boolean;
}) => {
  const { log } = useLogs();
  const { data, isLoading, refetch, isFetching, isSuccess, isError } = useQuery({
    queryKey: ['fetch-report', itemId],
    queryFn: () => ItemService.getPhonecheckReport(itemId),
    enabled: enabled && !!itemId,
    retry: retry,
  });

  useEffect(() => {
    if (isError) {
      log(
        {
          name: 'UseItemService Hook: Error fetching report',
          props: {
            itemId: itemId,
          },
        },
        LOG_TYPE.ERROR
      );
    }
  }, [isError]);

  return {
    funcGradingReport: data?.response,
    isFuncGradingLoading: isLoading,
    refetchFuncGrading: refetch,
    isFuncGradingFetching: isFetching,
    isFuncGradingSuccess: isSuccess,
  };
};

export const useGetItemImages = ({ itemId, enabled = true }: { itemId: string; enabled?: boolean }) => {
  const { data, isLoading, isSuccess, refetch } = useQuery({
    queryKey: [fetchKeys.phonePhoto.getItemImage, itemId],
    queryFn: () => ItemService.getImages(itemId),
    enabled: enabled,
  });

  return {
    itemImages: data?.response,
    isItemImagesLoading: isLoading,
    isItemImagesSuccess: isSuccess,
    refetchItem: refetch,
  };
};
