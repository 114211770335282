import { useEffect, useState } from 'react';

import type { Offer } from '@@/ItemAndGrading/entities/offer';

import { LOG_TYPE, useLogs } from '@/hooks/use-logs';
import { useSocketIo } from '@/hooks/use-socket-io';

type Props = {
  tradeInId?: string;
};

export const useOfferSocketIo = ({ tradeInId }: Props) => {
  const { log } = useLogs();
  const { client, hasConnected, on, emit } = useSocketIo();

  const [offer, setOffer] = useState({} as Offer);
  const [isOfferSuccess, setIsOfferSuccess] = useState(false);

  useEffect(() => {
    const handleConnect = async () => {
      on<Offer>(`trade-ins/${tradeInId}/currentOffer`, (offer) => {
        try {
          setIsOfferSuccess(true);
          setOffer(offer);
        } catch (error) {
          log({ name: 'Websocket: Current Offer Error', error: error as Error }, LOG_TYPE.CRITICAL);
        }
      });

      emit('trade-ins/currentOffer', tradeInId);
    };

    if (handleConnect && tradeInId && client) handleConnect();
  }, [tradeInId, client, hasConnected]);

  return { offer, isOfferSuccess };
};
