import { useEffect, useRef, useState } from 'react';

import { Socket, io } from 'socket.io-client';

import { useAuth } from '@clerk/nextjs';

import { CLERK_TEMPLATES } from '@@/Authentication/integration/clerk';

import { env } from '@/config/env';

import { LOG_TYPE, useLogs } from './use-logs';

export const useSocketIo = () => {
  const { getToken, isSignedIn } = useAuth();
  const [hasConnected, setHasConnected] = useState(false);
  const { log } = useLogs();
  const socketRef = useRef<Socket | undefined>(undefined);
  const hasConnectedRef = useRef(false);

  const connect = async () => {
    try {
      const token = await getToken({ template: CLERK_TEMPLATES.DEFAULT });
      const socket = io(env.TRADE_IN_SOCKETIO_ADDRESS, {
        query: { token },
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 5,
      });

      socket.on('connect', () => {
        setHasConnected(true);
      });

      socket.on('disconnect', async () => {
        setHasConnected(false);
        socketRef.current = undefined;
        const token = await getToken({ template: CLERK_TEMPLATES.DEFAULT });
        setTimeout(() => {
          if (!socket.connected && hasConnectedRef.current) {
            socket.io.opts.query = { token };
            socket.connect();
          }
        }, 1000);
      });

      socketRef.current = socket;
    } catch (error) {
      setHasConnected(false);
      socketRef.current = undefined;
      log({ name: 'Websocket Connect Error', error: error as Error }, LOG_TYPE.CRITICAL);
    }
  };

  useEffect(() => {
    if (isSignedIn && !hasConnectedRef.current) {
      hasConnectedRef.current = true;
      connect();
    }

    if (!isSignedIn && socketRef.current) disconnect();

    return () => {
      if (socketRef.current) {
        hasConnectedRef.current = false;
        disconnect();
      }
    };
  }, [isSignedIn]);

  const on = <T>(eventName: string, callback: (data: T) => void) => {
    if (!socketRef.current) return;

    socketRef.current.on(eventName, callback);
  };

  const emit = <T>(eventName: string, data: T) => {
    if (!socketRef.current) return;

    socketRef.current.emit(eventName, data);
  };

  const disconnect = () => {
    if (!socketRef.current) return;

    socketRef.current.disconnect();
    socketRef.current = undefined;
    setHasConnected(false);
  };

  return { client: socketRef.current, hasConnected, connect, disconnect, on, emit };
};
