import { ApiResponse, ApiResponseWithPagination } from '@/@types/api-response';
import { PaginationFilters } from '@/@types/pagination-data';

import { GIVE_UP_REASONS } from '@@/TradeIn/constants/why-give-up-options';
import { Contract } from '@@/TradeIn/entities/contract';
import { GiveUpReason } from '@@/TradeIn/entities/give-up';
import { TradeIn } from '@@/TradeIn/entities/trade-in';

import { tradeInApi } from '@/config/api';

export type MyTradeInsFilters = {
  status?: string;
  searchTerm?: string;
  storeOperatorId?: string;
};

export type ProductPriceResponse = {
  productDetails: {
    condition: string;
    title: string;
  };
  priceWithPitziInsurance: number;
  price: number;
};

export type GetDowngradedPricesResponse = ProductPriceResponse & {
  previousAcceptedInsurance: boolean;
};

export type UpdateTradeInRequest = {
  customer?: {
    name?: string;
    document?: {
      type: string;
      value: string;
    };
    email?: string;
    phone?: string;
    refId?: string;
    address?: {
      zipcode: string;
      address: string;
      number: string;
      addressLine2?: string;
      neighborhood: string;
      city: string;
      state: string;
    };
  };
  sponsorshipId?: string | null;
  acceptedBoost?: boolean;
  newProductPrice?: number | null;
  newProductId?: string | null;
};

export type CreateTradeInRequest = {
  name: string;
  document: {
    type: string;
    value: string;
  };
  email: string;
  phone: string;
  itemId: string;
  storeId: string;
  optInsurance: boolean | null;
  newProductPrice: number | null;
  refId?: string;
  address?: {
    zipcode: string;
    address: string;
    number: string;
    addressLine2?: string;
    neighborhood: string;
    city: string;
    state: string;
  };
};

type AcceptTradeInRequest = {
  name: string;
  document: {
    type: string;
    value: string;
  };
  email: string;
  phone: string;
  gradeSurveyId: string;
  storeId: string;
  optInsurance: boolean | null;
  newProductPrice: number | null;
};

export type FinalizeTradeInParams = {
  storeId: string;
  frontPhoto: File;
  backPhoto: File;
};

export type GiveUpTradeInRequest = {
  desirePrice?: number;
  comment?: string;
  reason: GIVE_UP_REASONS;
};

export default class TradeInService {
  static async getStoreTradeIns(storeId: string, orgId: string, params: PaginationFilters<MyTradeInsFilters>) {
    const { data } = await tradeInApi.get<ApiResponseWithPagination<TradeIn>>(
      `/v1/secure/stores/${storeId}/trade-ins`,
      {
        params,
        headers: {
          'Doji-Org-Id': orgId,
        },
      }
    );

    return data;
  }

  static async getDowngradedPrices(tradeInId: string) {
    const { data } = await tradeInApi.get<ApiResponse<GetDowngradedPricesResponse>>(
      `/v1/secure/trade-ins/${tradeInId}/downgrade/prices`
    );

    return data;
  }

  static async updateTradeIn(tradeInId: string, requestBody: UpdateTradeInRequest) {
    const { data } = await tradeInApi.put<ApiResponse<TradeIn>>(`/v1/secure/trade-ins/${tradeInId}`, requestBody);

    return data;
  }

  static async postSecureAcceptTradeIn(requestBody: AcceptTradeInRequest) {
    const { data } = await tradeInApi.post<ApiResponse<TradeIn>>(`/v1/secure/trade-ins`, requestBody);
    return data;
  }

  static async postCreate(requestBody: CreateTradeInRequest) {
    const { data } = await tradeInApi.post<ApiResponse<TradeIn>>(`/v1/secure/trade-ins`, requestBody);
    return data;
  }

  static async postSecureCreateV2(requestBody: { itemId: string }) {
    const { data } = await tradeInApi.post<ApiResponse<TradeIn>>(`/v2/secure/trade-ins`, requestBody);
    return data;
  }

  static async postPublicCreate(requestBody: CreateTradeInRequest) {
    const { data } = await tradeInApi.post<ApiResponse<TradeIn>>(`/v1/public/trade-ins`, requestBody);
    return data;
  }

  static async getPublicGradedProductPriceByGradeSurveyIdAndStoreId(
    gradeSurveyId: string,
    storeId: string,
    newProductPrice?: number
  ) {
    const requestWithNewPrice = `/v1/secure/stores/${storeId}/prices?gradeSurveyId=${gradeSurveyId}&newProductPrice=${newProductPrice}`;
    const requestWithoutNewPrice = `/v1/secure/stores/${storeId}/prices?gradeSurveyId=${gradeSurveyId}`;

    const { data } = await tradeInApi.get<ApiResponse<ProductPriceResponse>>(
      newProductPrice ? requestWithNewPrice : requestWithoutNewPrice
    );

    return data;
  }

  static async postSendContractSignature(tradeInId: string, signature: string) {
    const { data } = await tradeInApi.post<ArrayBuffer>(
      `/v1/secure/trade-ins/${tradeInId}/signed-contract`,
      {
        signature,
      },
      {
        // This is necessary for it works
        headers: {
          Accept: '*/*',
        },
        responseType: 'arraybuffer',
      }
    );

    return data;
  }

  static async getContracts(tradeInId: string) {
    const { data } = await tradeInApi.get<ApiResponse<Contract>>(`/v1/secure/trade-ins/${tradeInId}/contract`);

    return data;
  }

  static async generateContract(tradeInId: string) {
    const { data } = await tradeInApi.post<ArrayBuffer>(
      `/v1/secure/trade-ins/${tradeInId}/contract`,
      {},
      {
        // This is necessary for it works
        headers: {
          Accept: '*/*',
        },
        responseType: 'arraybuffer',
      }
    );

    return data;
  }

  static async postFinalize(tradeInId: string, storeId: string) {
    const { data } = await tradeInApi.post<{ success: boolean; message: string }>(
      `/v1/secure/trade-ins/${tradeInId}/finalize`,
      {
        storeId,
      }
    );

    return data;
  }

  static async postTriggerAICosmeticGrade(itemId: string) {
    const { data } = await tradeInApi.post<ApiResponse<null>>(`/v1/secure/items/${itemId}/images/confirm`);

    return data;
  }

  static async postDowngrade(tradeInId: string) {
    const { data } = await tradeInApi.post<ApiResponse<TradeIn>>(`/v2/secure/trade-ins/${tradeInId}/downgrade`);

    return data;
  }

  static async findById(tradeInId: string) {
    const { data } = await tradeInApi.get<ApiResponse<TradeIn>>(`/v1/secure/trade-ins/${tradeInId}`);

    return data;
  }

  static async giveUp(tradeInId: string, body: GiveUpTradeInRequest) {
    const { data } = await tradeInApi.post<ApiResponse<GiveUpReason>>(
      `/v1/secure/trade-ins/${tradeInId}/give-up`,
      body
    );

    return data;
  }
}
